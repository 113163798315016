import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RiArrowRightFill } from "react-icons/ri";
import { TiWarning } from "react-icons/ti";
// import { MdError } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";
import Loading from "../Assets/loading.gif";
import { useWallet } from "@txnlab/use-wallet";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import { algosdk, client, app_id, fame, fame_global, usdc } from "../utils";
import { useTimer } from "../Controller/Constants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addDescription } from "../Lib/Description";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles

export default function Submit({ type }) {
  const [description, setDescription] = useState("");
  const [recipient, setRecipient] = useState("");
  const [proposalType, setProposalType] = useState("n");
  const [value, setValue] = useState(""); //These are values from the form, to be passed in the function but we have set values in txns.js
  const { signTransactions, activeAddress } = useWallet("");
  const [loading, setLoading] = useState(false);
  const [txnsId, setTxnsId] = useState(false);
  const [warning, setWarning] = useState(false);
  const [process, setProcess] = useState();
  const [details, setDetails] = useState();
  const [address, setAddress] = useState("");

  const handleDetails = (value) => {
    setDetails(value);
  };

  const warn = () => {
    toast.warn(warning, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "dark",
    });
  };

  const error = () => {
    toast.error("Transaction Cancelled!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "dark",
    });
  };

  const creatorAccount = activeAddress;
  const {
    algouBalance,
    algoBalance,
    stakedAmount,
    walletBalance,
    totalStake,
    treasuryBalance,
    glFameBalance,
    usdcBalance,
  } = useTimer();

  // console.log(glFameBalance);
  // console.log(fame_global);

  useEffect(() => {
    if (!creatorAccount) {
      setAddress("CONNECT WALLET");
    } else {
      setAddress(creatorAccount);
    }
  }, [creatorAccount, setAddress]);

  let [isOpen1, setIsOpen1] = useState(false);

  function openModal1() {
    setIsOpen1(true);
  }
  function closeModal1() {
    setIsOpen1(false);
  }

  //Modal for Donation Success
  let [isOpen2, setIsOpen2] = useState(false);

  function openModal2() {
    setIsOpen2(true);
  }
  function closeModal2() {
    setIsOpen2(false);
  }

  let [isOpen6, setIsOpen6] = useState(false);

  function openModal6() {
    setIsOpen6(true);
  }
  function closeModal6() {
    setIsOpen6(false);
  }

  const cancelButtonRef = useRef(null);
  const realFameBal = treasuryBalance - totalStake;

  const propose = async (
    sender,
    index,
    proposal,
    proposal_type,
    receiverAmount,
    proposal_token,
    fame
  ) => {
    try {
      console.log(proposal_token, proposal_type);
      const appArgs = [];
      appArgs.push(
        new Uint8Array(Buffer.from("pr")), //noop
        new Uint8Array(Buffer.from(proposal)), //proposal
        new Uint8Array(Buffer.from(proposal_type)), //proposal type
        algosdk.encodeUint64(receiverAmount), //proposal value
        algosdk.encodeUint64(proposal_token) //proposal token to withdraw
      );
      let params = await client.getTransactionParams().do();
      params.fee = 1000;
      params.flatFee = true;

      console.log(proposal_token);
      console.log(fame);
      setProcess("Submitting...  ");
      // create unsigned noop transaction
      let nptxn = algosdk.makeApplicationNoOpTxn(
        sender,
        params,
        index,
        appArgs,
        [recipient, recipient],
        undefined,
        [fame]
      );

      // create unsigned asset payment transaction
      let ptxn = algosdk.makePaymentTxnWithSuggestedParams(
        sender,
        algosdk.getApplicationAddress(index),
        2000000,
        undefined,
        undefined,
        params
      );

      // create unsigned asset transfer transaction

      let txns = [nptxn, ptxn];
      txns = algosdk.assignGroupID(txns);
      // Sign the transactions together using the wallet

      const encodedTransaction1 = algosdk.encodeUnsignedTransaction(nptxn);
      const encodedTransaction2 = algosdk.encodeUnsignedTransaction(ptxn);

      const signedTransactions = await signTransactions([
        encodedTransaction1,
        encodedTransaction2,
      ]);
      const date = Date.now();
      const newDescription = {
        date,
        short_desc: description,
        long_desc: details,
        wallet: creatorAccount,
      };

      try {
        const addedDescriptionRef = await addDescription(newDescription);
        console.log("Description added with ID: ", addedDescriptionRef.id);
        // Reset form or navigate to another page
      } catch (error) {
        console.error("Error adding Long Description: ", error);
      }

      let tx = await client.sendRawTransaction(signedTransactions).do();
      //console.log("Noop + Asset Transfer Transaction : " + tx.txId);

      // Wait for transaction to be confirmed
      const confirmedTxn = await algosdk.waitForConfirmation(
        client,
        tx.txId,
        4
      );
      console.log("confirmed" + JSON.stringify(confirmedTxn));

      // Get the completed Transaction
      // console.log(
      //   "Transaction " +
      //     tx.txId +
      //     " confirmed in round " +
      //     confirmedTxn["confirmed-round"]
      // );
      localStorage.setItem("hasFunctionRun", "false");
      // const newProposal = {
      //   date: "0",
      //   down: "0",
      //   endTime,
      //   description,
      //   proposalType,
      //   status: "0",
      //   txns: "0",
      //   up: "0",
      //   receiverAmount,
      // };

      // try {
      //   const addedProposalRef = await addProposal(newProposal);
      //   //console.log("Proposal added with ID: ", addedProposalRef.id);
      //   // Reset form or navigate to another page
      // } catch (error) {
      //   //console.error("Error adding proposal: ", error);
      // }
      setTxnsId(tx.txId);
      setLoading(false);
      openModal2(true);

      setDescription("");
      setValue("");
    } catch (err) {
      console.log(err);
      if (
        err.message.includes("Rejected") ||
        err.message.includes("Confirmation Failed")
      ) {
        setLoading(false);
        toast.error("Transaction Cancelled!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else if (
        err.message.includes("overspend") ||
        err.message.includes("below min")
      ) {
        setLoading(false);
        toast.warn("Not Enough Algo for transaction fee!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else {
        setLoading(false);
        await setWarning("Network Error. Please Try Again!");
        console.log(err);
        toast.error("Network Error. Please Try Again!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
        toast.error(err, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      }
    }
  };

  const Proposal = async () => {
    setIsOpen1(false);
    const maxFamePropose = ((realFameBal * 10e-1) / 10).toFixed(2);
    const maxAlgoPropose = ((algoBalance * 10e-1) / 10).toFixed(2);
    const maxUsdcPropose = ((usdcBalance * 10e-1) / 10).toFixed(2);
    const maxGlFamePropose = ((glFameBalance * 10e-1) / 10).toFixed(2);

    console.log(maxGlFamePropose, maxUsdcPropose);
    setLoading(true);
    if (!creatorAccount) {
      setLoading(false);
      setLoading(false);
      toast.warn("Please Connect Wallet!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    } else if (stakedAmount <= 0) {
      setLoading(false);
      toast.warn("You must Stake before Proposing!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    } else if (algouBalance < 1) {
      setLoading(false);
      toast.warn("Not enough Algo for transaction fee!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    } else if (proposalType === "choose") {
      setLoading(false);
      toast.warn("Choose Proposal type", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    } else if (proposalType === "n") {
      //console.log(value);
      //console.log(maxFamePropose);
      if (Number(value) > Number(maxGlFamePropose)) {
        setLoading(false);
        toast.warn(`Max amount you can propose ${maxGlFamePropose} FAME`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else if (description === "") {
        setLoading(false);
        toast.warn("Write a description for proposal", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else if (value === "") {
        setLoading(false);
        toast.warn("Choose an amount", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else {
        const num = Number(value) * 10e1;
        await propose(
          creatorAccount,
          app_id,
          description,
          proposalType, //Proposal type too is defined
          num, //Same here
          fame_global,
          fame_global
        );
      }
    } else if (proposalType === "nm") {
      //console.log(value);
      //console.log(maxFamePropose);
      console.log(value);
      if (Number(value) > Number(maxFamePropose)) {
        setLoading(false);
        toast.warn(`Max amount you can propose ${maxFamePropose} FAME`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else if (description === "") {
        setLoading(false);
        toast.warn("Write a description for proposal", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else {
        const pt = "n";
        const num = 1 * 10e5;

        await propose(
          creatorAccount,
          app_id,
          description,
          pt, //Proposal type too is defined
          num, //Same here
          fame,
          fame
        );
      }
    } else if (proposalType === "nu") {
      //console.log(value);
      //console.log(maxFamePropose);
      if (Number(value) > Number(maxUsdcPropose)) {
        setLoading(false);
        toast.warn(`Max amount you can propose ${maxUsdcPropose} USDC`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else if (description === "") {
        setLoading(false);
        toast.warn("Write a description for proposal", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else if (value === "") {
        setLoading(false);
        toast.warn("Choose an amount", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else {
        const pt = "n";
        const num = Number(value) * 10e5;
        await propose(
          creatorAccount,
          app_id,
          description,
          pt, //Proposal type too is defined
          num, //Same here
          usdc,
          usdc
        );
      }
    } else if (proposalType === "pa") {
      if (Number(value) > Number(maxAlgoPropose)) {
        setLoading(false);
        toast.warn(`Max amount you can propose ${maxAlgoPropose} ALGO`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else if (description === "") {
        setLoading(false);
        toast.warn("Write a description for proposal", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else if (value === "") {
        setLoading(false);
        toast.warn("Choose an amount", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else {
        let proposal_fee = 5;
        const num = Number(value * 10);
        await propose(
          creatorAccount,
          app_id,
          description,
          proposalType, //Proposal type too is defined
          num, //Same here
          fame_global,
          fame_global
        );
      }
    } else if (proposalType === "re") {
      //console.log(value);
      //console.log(maxFamePropose);
      if (value > maxFamePropose) {
        setLoading(false);
        toast.warn(`Max amount you can propose ${maxFamePropose} FAME`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else if (description === "") {
        toast.warn("Type your Question", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else {
        const prefix = "ref//";
        const question = `${prefix}${description}`;
        let proposal_fee = 5;
        const num = Number(0.1 * 10e1);
        const pt = "n";
        //console.log(num);
        await propose(
          creatorAccount,
          app_id,
          question,
          pt, //Proposal type too is defined
          num, //Same here
          fame_global,
          fame_global
        );
      }
    }
  };

  const maxLength = 100;
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue);

    if (inputValue.length === maxLength) {
      setMaxTextAlmostReached(false);

      setMaxTextReached(true);
    } else {
      setMaxTextReached(false);
    }

    if (inputValue.length >= maxLength - 19 && inputValue.length < maxLength) {
      setMaxTextAlmostReached(true);
    } else {
      setMaxTextAlmostReached(false);
    }
  };

  // And in your component state
  const [maxTextReached, setMaxTextReached] = useState(false);
  const [maxTextAlmostReached, setMaxTextAlmostReached] = useState(false);

  return (
    <>
      {loading && (
        <div className="top-0 left-0 w-full h-full z-50 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100">
          <div
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            style={{
              backgroundColor: "white",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "2",
              height: "180px",
              width: "300px",
              justifyContent: "center",
            }}
          >
            <img src={Loading} alt="loading" className="flex ml-20" />
            <div>
              <p className="text-center">{process}</p>
            </div>
          </div>
        </div>
      )}

      <div className="col-span-3" style={{ display: type }}>
        <p className="text-black sec-two-head">
          Giving ownership to the people
        </p>
        <p className="text-primary sec-two-sub">Submit governor proposal </p>
        <div className=" mt-10">
          <div className="form-space mb-5 relative lg:grid grid-cols-10">
            <p className="text-primary col-span-2 text-left">Proposal Type</p>
            <select
              className="col-span-8 mb-5 font-bold appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-state"
              onChange={(e) => setProposalType(e.target.value)}
            >
              <option value="choose">Choose Proposal Type</option>
              <option value="pa">Withdraw Algo from Treasury</option>
              <option value="n">Withdraw Fame from Treasury</option>
              <option value="nu">Withdraw Usdc from Treasury</option>
              <option value="re">Referendum</option>
              <option value="nm">Add new Member</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>

          {proposalType === "re" ? (
            <>
              <div className="form-space mb-5 lg:grid grid-cols-10 relative">
                <p className="text-primary col-span-2 text-left">
                  Question({description.length}/{maxLength})
                </p>
                <textarea
                  className="col-span-8 bg-gray-200 w-full col-span-8 text-gray-700 border rounded-md py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder={
                    "1. Stake Before You Propose: You must have staked $FAME tokens in the DAO before you can make a proposal.\n2. Proposal Fee: Each proposal costs 0.05 $FAME.\n3. Limit Your Withdrawal: For each proposal, you can only request a maximum of 10% of the available $FAME or Algo in the Treasury.\n4. Explain Your Proposal: Clearly state why you believe the community should approve a withdrawal from the Treasury to your wallet."
                  }
                  rows="3"
                  onChange={handleChange}
                  maxLength={maxLength}
                ></textarea>

                {maxTextAlmostReached && (
                  <p
                    style={{ color: "#ffc444" }}
                    className="col-end-11 col-start-3 col-span-8 text-left"
                  >
                    You're approaching the maximum character limit!
                  </p>
                )}
                {maxTextReached && (
                  <p
                    style={{ color: "red" }}
                    className="col-end-11 col-start-3 col-span-8 text-left"
                  >
                    Maximum character reached!
                  </p>
                )}
              </div>
              <div className="form-space mb-5 lg:grid grid-cols-10 ">
                <p className="text-primary col-span-2 text-left">Initiator</p>
                <input
                  className=" bg-gray-200 col-span-8 text-gray-700 border rounded-md py-3 px-4 mb-3 w-full leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  disabled
                  value={address}
                  placeholder="Type wallet address"
                />
              </div>
              <div className="form-space mb-20 lg:grid grid-cols-10 relative">
                <p className="text-primary col-span-2 text-left">
                  Propsal Pitch
                </p>
                <ReactQuill
                  theme="snow" // You can choose different themes
                  onChange={handleDetails}
                  className="col-span-8"
                />
              </div>
              <div className="form-space mb-5 lg:grid grid-cols-10">
                <div className="col-start-3 col-end-10">
                  <button
                    onClick={openModal1}
                    className="flex i-fame uppercase block transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white bg-primary ring-1 ring-gray-900/10 hover:ring-gray-900/20"
                  >
                    submit referendum
                    <span>
                      <RiArrowRightFill className=" ml-5 inline-block" />
                    </span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="form-space mb-5 lg:grid grid-cols-10 relative">
                <p className="text-primary col-span-2 text-left">
                  Description ({description.length}/{maxLength})
                </p>
                <textarea
                  className="col-span-8 bg-gray-200 w-full col-span-8 text-gray-700 border rounded-md py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder={
                    "1. Stake Before You Propose: You must have staked $FAME tokens in the DAO before you can make a proposal.\n2. Proposal Fee: Each proposal costs 0.05 $FAME.\n3. Limit Your Withdrawal: For each proposal, you can only request a maximum of 10% of the available $FAME or Algo in the Treasury.\n4. Explain Your Proposal: Clearly state why you believe the community should approve a withdrawal from the Treasury to your wallet."
                  }
                  rows="3"
                  onChange={handleChange}
                  maxLength={maxLength}
                ></textarea>

                {maxTextAlmostReached && (
                  <p
                    style={{ color: "#ffc444" }}
                    className="col-end-11 col-start-3 col-span-8 text-left"
                  >
                    You're approaching the maximum character limit!
                  </p>
                )}
                {maxTextReached && (
                  <p
                    style={{ color: "red" }}
                    className="col-end-11 col-start-3 col-span-8 text-left"
                  >
                    Maximum character reached!
                  </p>
                )}
              </div>

              <div className="form-space mb-5 lg:grid grid-cols-10 ">
                {proposalType === "nm" ? (
                  <p className="text-primary col-span-2 text-left">
                    Proposed new Governor
                  </p>
                ) : (
                  <p className="text-primary col-span-2 text-left">Receiver</p>
                )}

                <input
                  className=" bg-gray-200 col-span-8 text-gray-700 border rounded-md py-3 px-4 mb-3 w-full leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  onChange={(e) => setRecipient(e.target.value)}
                  placeholder="Type wallet address of Proposed new governor"
                />
              </div>
              <div className="form-space mb-5 lg:grid grid-cols-10">
                <p className="text-primary col-span-2 text-left">Value</p>
                {proposalType === "nm" ? (
                  <input
                    className=" bg-gray-200 col-span-8 text-gray-700 border rounded-md py-3 px-4 mb-3 w-full leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    disabled
                    placeholder="1 membership card"
                  />
                ) : (
                  <input
                    className=" bg-gray-200 col-span-8 text-gray-700 border rounded-md py-3 px-4 mb-3 w-full leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="number"
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="16,000"
                  />
                )}
              </div>
              <div className="form-space mb-20 lg:grid grid-cols-10 relative">
                <p className="text-primary col-span-2 text-left">
                  Propsal Pitch
                </p>
                <ReactQuill
                  className="col-span-8"
                  theme="snow" // You can choose different themes
                  onChange={handleDetails}
                />
              </div>

              <div className="form-space mb-5 lg:grid grid-cols-10">
                <div className="col-start-3 col-end-10">
                  <button
                    onClick={openModal1}
                    className="flex i-fame uppercase block transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white bg-primary ring-1 ring-gray-900/10 hover:ring-gray-900/20"
                  >
                    submit proposal
                    <span>
                      <RiArrowRightFill className=" ml-5 inline-block" />
                    </span>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Transition.Root show={isOpen1} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal1}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0  sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold text-gray-900"
                        >
                          Confirm Proposal
                        </Dialog.Title>
                      </div>
                    </div>
                    <p className="text-sm">Description: {description}</p>
                    {proposalType === "n" || "pa" ? (
                      <p className="text-sm">
                        Proposed Value: {value}
                        {proposalType === "n"
                          ? " $FAME"
                          : proposalType === "pa"
                          ? " $ALGO"
                          : proposalType === "nu"
                          ? " $USDC"
                          : ""}
                      </p>
                    ) : (
                      ""
                    )}
                    <p className="font-semibold lg:mt-3 mt-3">Fees :</p>
                    <p className="text-sm font-semibold">2 $ALGO </p>
                  </div>

                  <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 grid grid-cols-2 gap-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => closeModal1(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      onClick={Proposal}
                    >
                      Submit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Proposal Success */}
      <Transition.Root show={isOpen2} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal2}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center">
                      <BsFillCheckCircleFill className="text-secondary donate-check inline-block" />
                      <p className="mt-3 donate-confirmed">
                        Successfully Submitted !
                      </p>
                      <div className="flex">
                        <p
                          className="mt-1"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Transaction Id :{" "}
                          <span
                            onClick={openModal6}
                            className="text-secondary cursor-pointer"
                          >
                            {txnsId}
                          </span>
                        </p>
                        <CopyToClipboard text={txnsId}>
                          <button className="inline-flex ml-3 item-center transition ease-in-out hover:text-secondary text-white hover:bg-white hover:outline delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-2 py-1 text-base font-semibold leading-7 text-white bg-secondary ring-1 ring-gray-900/10 hover:ring-gray-900/2">
                            <p>Copy</p>
                            <HiOutlineClipboardDocument
                              style={{ fontSize: "20px" }}
                              className="ml-2 mt-1  text-center"
                            />
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* iframe */}
      <Transition.Root show={isOpen6} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal6}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center ">
                      <iframe
                        src={`https://algoexplorer.io/tx/${txnsId}`}
                        width={350}
                        height={500}
                        title="Transaction"
                      ></iframe>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
