// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDH4SY5FLbHBbQl_Z_rcFQwhshjjbrXfEk",
  authDomain: "fame-webapp.firebaseapp.com",
  projectId: "fame-webapp",
  storageBucket: "fame-webapp.appspot.com",
  messagingSenderId: "42711031949",
  appId: "1:42711031949:web:ae81a4a7ab5640ab7e42c4",
  measurementId: "G-BV7CG5GJZC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
