import React from "react";
import "./App.css";
import Error from "./Pages/404";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Dao from "./Pages/Dao";

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import {
  reconnectProviders,
  initializeProviders,
  WalletProvider,
} from "@txnlab/use-wallet";

import { ConstantProvider } from "./Controller/Constants";
import DaoNav from "./Components/DaoNav";
import { toast, ToastContainer } from "react-toastify";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

function App() {
  const walletProviders = initializeProviders(["pera"]); // Initialize only "pera" provider

  React.useEffect(() => {
    reconnectProviders(walletProviders);
  }, []);

  return (
    <ConstantProvider>
      <ToastContainer />
      <WalletProvider value={walletProviders}>
        <div className="App">
          <BrowserRouter>
            <ScrollToTop>
              <Routes>
                <Route
                  path="/"
                  element={
                    <div>
                      <DaoNav />
                      <Dao />
                      <Footer />
                    </div>
                  }
                />

                <Route
                  path="*"
                  element={
                    <div>
                      <Navbar />
                      <Error />
                      <Footer />
                    </div>
                  }
                />

                {/* <Route path="/portuguese" element="https://pg.algofame.org" /> */}
              </Routes>
            </ScrollToTop>
          </BrowserRouter>
        </div>
      </WalletProvider>
    </ConstantProvider>
  );
}

export default App;
