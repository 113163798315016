export const algosdk = require("algosdk");

export let client = new algosdk.Algodv2(
  "",
  "https://testnet-api.algonode.cloud",
  ""
);
// export let client = new algosdk.Algodv2(
//   "",
//   "https://mainnet-api.algonode.cloud",
//   ""
// );

// export let app_id = 225262568;

export let fame = 628646526; //testnet

// export let app_id = 629975407;
export let app_id = 648737864;
export let fame_global = 148814612;
export let usdc = 10458941;
// export let fame = 142838028; // The Nainnet I found

export const creatorAccount = JSON.parse(localStorage.getItem("walletconnect"))
  ?.accounts[0];

export let duration = 300;
